import React from "react";
import Layout from "../components/Layout";
import { Box, Typography } from "@material-ui/core";
import theme from "../style/theme";

// TODO: i18n 404 page
export default function Error404Template(props) {
  const {
    data: {
      site: {
        siteMetadata: { subsites }
      }
    }
  } = props;
  let subsite = subsites[0];
  if (props.location) {
    // switch subsite based on URL path... e.g. /zh/bad-url will switch to zh subsite
    subsite =
      subsites.find(
        s =>
          s.prefixPath.length &&
          props.location.pathname.startsWith(s.prefixPath)
      ) || subsite;
  }
  return (
    <Layout subsite={subsite}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box
          flexGrow={1}
          textAlign="center"
          paddingTop={4}
          paddingBottom={12}
          paddingX={8}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
          }}
        >
          <Box marginBottom={4}>
            <Typography
              color="inherit"
              variant="h2"
              style={{
                fontWeight: "bold",
                fontFamily:
                  "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif",
                marginBottom: 4
              }}
            >
              404 Error
            </Typography>
            <Typography color="inherit" variant="body1">
              The requested page was not found.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        phoneNumber
        title
        subsites {
          key
          lang
          country
          prefixPath
        }
      }
    }
  }
`;
